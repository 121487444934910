import api from '@/main'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
export async function getTopVisitChart () {
  const userStore = useUserStore()
  const { getUserPositionId } = storeToRefs(userStore)
  try {
    const params = new URLSearchParams()
    if (getUserPositionId.value) {
      params.append('positionId', getUserPositionId.value)
    }
    const paramsString = params.toString()
    const response = await api.get(`/v1/chart/top-visits?${paramsString}`)
    return response.data.data
  } catch (err) {
    throw new Error(err)
  }
}

export async function getTopProductsChart () {
  const userStore = useUserStore()
  const { getUserPositionId } = storeToRefs(userStore)
  try {
    const params = new URLSearchParams()
    if (getUserPositionId.value) {
      params.append('positionId', getUserPositionId.value)
    }
    const paramsString = params.toString()
    const response = await api.get(`/v1/chart/top-products?${paramsString}`)
    return response.data.data
  } catch (err) {
    throw new Error(err)
  }
}

export async function getTopOffersChart () {
  const userStore = useUserStore()
  const { getUserPositionId } = storeToRefs(userStore)
  try {
    const params = new URLSearchParams()
    if (getUserPositionId.value) {
      params.append('positionId', getUserPositionId.value)
    }
    const paramsString = params.toString()
    const response = await api.get(`/v1/chart/top-offers?${paramsString}`)
    return response.data.data
  } catch (err) {
    throw new Error(err)
  }
}
